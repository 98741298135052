import CheckPage from "./pages/CheckPage";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import SuccessResultPage from "./pages/SuccessResultPage";
import FailedResultPage from "./pages/FailedResultPage";

function App() {
  return (
    <MemoryRouter>
      <Routes>
        <Route path="/sn-result/success" element={<SuccessResultPage />} />
        <Route path="/sn-result/failed" element={<FailedResultPage />} />
        <Route path="/" element={<CheckPage />} />
      </Routes>
    </MemoryRouter>
  );
}

export default App;
