import ProductThumbnailComponent from "../ProductThumbnailComponent";
import styles from "./ProductImageComponent.module.css";

function ProductImageComponent() {
  function generateRandomImage() {
    return `https://picsum.photos/1024/560?random=${Math.random()}`;
  }

  return (
    <div className={styles.container}>
      <div className={styles.thumbnails}>
        <ProductThumbnailComponent
          className={styles.thumbnail}
          src={generateRandomImage()}
        />
        <ProductThumbnailComponent
          className={styles.thumbnail}
          src={generateRandomImage()}
          selected
        />
        <ProductThumbnailComponent
          className={styles.thumbnail}
          src={generateRandomImage()}
        />
        <ProductThumbnailComponent
          className={styles.thumbnail}
          src={generateRandomImage()}
        />
      </div>
      <div className={styles.mainImageContainer}>
        <img src={generateRandomImage()} alt="Main" />
      </div>
    </div>
  );
}

export default ProductImageComponent;
