import styles from "./ProductInfoComponent.module.css";
import { ProductInfoComponentProps } from "./types";

import HeadingComponent from "../../../../components/HeadingComponent";
import ProductInfoItemComponent from "../ProductInfoItemComponent";

function ProductInfoComponent(props: ProductInfoComponentProps) {
  return (
    <div className={styles.container}>
      <HeadingComponent variant="h2" className={styles.h2}>
        {`${props.device.brand} ${props.device.name}`}
      </HeadingComponent>
      <HeadingComponent variant="h3" className={styles.h3}>
        Product Detail
      </HeadingComponent>
      <div className={styles.productDetail}>
        <ProductInfoItemComponent
          title="Serial Number"
          value={props.device.serialNumber}
        />
        <ProductInfoItemComponent
          title="Warranty Start Date"
          value={props.device.warrantyStartDate
            .setZone("Asia/Jakarta")
            .toFormat("dd MMMM yyyy")}
        />
        <ProductInfoItemComponent title="Brand" value={props.device.brand} />
        <ProductInfoItemComponent
          title="Device Name"
          value={props.device.name}
        />
      </div>
    </div>
  );
}

export default ProductInfoComponent;
