import styles from "./ProductInfoItemComponent.module.css";
import { ProductInfoItemComponentProps } from "./types";

function ProductInfoItemComponent(props: ProductInfoItemComponentProps) {
  return (
    <div className={styles.item}>
      <span className={styles.title}>{props.title}</span>
      <span className={styles.value}>{props.value}</span>
    </div>
  );
}

export default ProductInfoItemComponent;
