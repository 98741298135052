import lodash from "lodash";
import styles from "./ButtonComponent.module.css";
import { ButtonComponentProps } from "./types";

function ButtonComponent(props: ButtonComponentProps) {
  const classes = [
    styles.btn,
    styles[`btn-${lodash(props).get("variant", "outlined")}`],
    styles[`btn-${lodash(props).get("color", "primary")}`],
    props.className,
  ];

  return (
    <button
      {...props}
      className={classes.join(" ")}
      onClick={props.onClick}
      type={props.type ?? "button"}
    >
      {props.children}
    </button>
  );
}

export default ButtonComponent;
