import styles from "./WarrantyInfoComponent.module.css";
import { WarrantyInfoComponentProps } from "./types";

import HeadingComponent from "../../../../components/HeadingComponent";
import RowComponent from "../../../../components/RowComponent";
import TableHeaderComponent from "../../../../components/TableHeaderComponent";
import { Badge } from "@mantine/core";

function WarrantyInfoComponent(props: WarrantyInfoComponentProps) {
  return (
    <div className={styles.container}>
      <HeadingComponent className={styles.h3} variant="h3">
        Warranty Details
      </HeadingComponent>
      <table className={styles.table}>
        <TableHeaderComponent
          items={["Part Name", "Warranty Duration", "Valid Until", "Status"]}
        />
        <tbody>
          <RowComponent
            items={props.warrantyInformation.map((w) => [
              w.part,
              `${w.period} months`,
              w.validUntil.toFormat("dd MMMM yyyy"),
              w.inWarranty ? (
                <Badge color="green">In Warranty</Badge>
              ) : (
                <Badge color="red">Out of Warranty</Badge>
              ),
            ])}
          />
        </tbody>
      </table>
      <table className={styles.mobileTable}>
        <TableHeaderComponent items={["Part Name", "Status"]} />
        <tbody>
          <RowComponent
            items={props.warrantyInformation.map((w) => [
              {
                value: `${w.part} <br /> <small>${
                  w.period
                } months</small><br /> <small><i>${w.validUntil.toFormat(
                  "dd MMMM yyyy"
                )}</i></small>`,
                html: true,
              },
              w.inWarranty ? (
                <Badge color="green">In Warranty</Badge>
              ) : (
                <Badge color="red">Out of Warranty</Badge>
              ),
            ])}
          />
        </tbody>
      </table>
    </div>
  );
}

export default WarrantyInfoComponent;
