import React from "react";
import styles from "./RowComponent.module.css";
import { RowComponentProps } from "./types";

function RowComponent(props: RowComponentProps) {
  if (props.items && props.items.length > 0) {
    return (
      <React.Fragment>
        {props.items.map((item, rowIndex) => (
          <tr className={styles.row} key={rowIndex}>
            {item.map((item, colIndex) => {
              if (item.html) {
                return (
                  <td
                    key={`column-${colIndex}`}
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  ></td>
                );
              } else {
                return <td key={`column-${colIndex}`}>{item}</td>;
              }
            })}
          </tr>
        ))}
      </React.Fragment>
    );
  } else return null;
}

export default RowComponent;
