import { Properties, Constructor } from "./types";
import { DateTime } from "luxon";

interface DeviceModel extends Properties {}
class DeviceModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(doc: Record<string, any>) {
    return new DeviceModel({
      serialNumber: doc.serial_number,
      warrantyStartDate: DateTime.fromISO(doc.warranty_start_date),
      name: doc.device,
      brand: doc.brand,
      warrantyInformation: doc.warranty_information.map(
        (w: Record<string, string>) => ({
          part: w.part,
          period: w.warranty_period,
          validUntil: DateTime.fromISO(w.valid_until),
          inWarranty: w.still_in_warranty,
        })
      ),
    });
  }
}

export default DeviceModel;
