import styles from "./ProductThumbnailComponent.module.css";
import { ProductThumbnailComponentProps } from "./types";

function ProductThumbnailComponent(props: ProductThumbnailComponentProps) {
  return (
    <div className={[styles.container, props.className].join(" ")}>
      <img className={styles.thumbnail} src={props.src} alt="Thumbnail" />
      {props.selected ? null : <div className={styles.layer}>&nbsp;</div>}
    </div>
  );
}

export default ProductThumbnailComponent;
