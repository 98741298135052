import styles from "./SuccessResultPage.module.css";
import { useNavigate, useLocation } from "react-router-dom";

import TitleComponent from "../../components/TitleComponent";
import ButtonComponent from "../../components/ButtonComponent";
import MainContainerComponent from "../../components/MainContainerComponent";
import ProductInfoComponent from "./components/ProductInfoComponent";
import ProductImageComponent from "./components/ProductImageComponent";
import WarrantyInfoComponent from "./components/WarrantyInfoComponent";
import DeviceModel from "../../models/device";

function SuccessResultPage() {
  const navigate = useNavigate();
  const location = useLocation();

  function _doCheckAnotherSerialNumber() {
    navigate(-1);
  }

  if (!(location.state instanceof DeviceModel)) {
    navigate(-1);
  }

  return (
    <MainContainerComponent>
      <div className={styles.titleContainer}>
        <TitleComponent title="Serial Number Checker" />
      </div>
      <div className={styles.productContent}>
        <ProductImageComponent />
        <ProductInfoComponent device={location.state as DeviceModel} />
      </div>
      <WarrantyInfoComponent
        warrantyInformation={
          (location.state as DeviceModel).warrantyInformation
        }
      />
      <div className={styles.bottomButton}>
        <ButtonComponent
          variant="outlined"
          color="primary"
          onClick={_doCheckAnotherSerialNumber}
        >
          Check Another Serial Number
        </ButtonComponent>
      </div>
    </MainContainerComponent>
  );
}

export default SuccessResultPage;
