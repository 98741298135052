import AppConfig from "../../configs";
import DeviceModel from "../../models/device";
import styles from "./CheckPage.module.css";
import validator from "validator";
import axios from "axios";
import { ChangeEvent, FormEvent } from "react";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TitleComponent from "../../components/TitleComponent";
import ButtonComponent from "../../components/ButtonComponent";
import MainContainerComponent from "../../components/MainContainerComponent";

function CheckPage() {
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function _doSubmitSerialNumber(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoading(true);

    try {
      const url = `${AppConfig.apiUrl}/serial-numbers/check`;
      const headers = { apikey: "mZjZdZHgbQWnkvTMXLLoO35vp3tMkp1L" };
      const queryString = new URLSearchParams({
        serial_number: serialNumber,
        vendor_name: "own-website",
      }).toString();

      const res = await axios.get(`${url}?${queryString}`, { headers });
      if (res.status === 200) {
        if (res.data.error_text) {
          throw new Error(`${res.data.error_text}`);
        }

        if (res.data.validity === "valid") {
          const device = DeviceModel.fromResponse(res.data);
          navigate("/sn-result/success", { state: device });
        } else {
          navigate("/sn-result/failed");
        }
      } else {
        throw new Error(`${res.status} - ${res.statusText}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function _onInputChange(e: ChangeEvent<HTMLInputElement>) {
    setSerialNumber(e.target.value.toUpperCase());
  }

  useEffect(() => {
    setDisabledButton(validator.isEmpty(serialNumber));
  }, [serialNumber]);

  return (
    <MainContainerComponent>
      <form onSubmit={_doSubmitSerialNumber} className={styles.form}>
        <TitleComponent title="Serial Number Checker" />
        <p className={styles.p}>
          Protect Yourself From Fake, Expired, & Substandard Products
        </p>
        <input
          className={styles.input}
          type="text"
          placeholder="Enter serial number and check your product"
          value={serialNumber}
          onChange={_onInputChange}
          disabled={loading}
        />
        <ButtonComponent
          type="submit"
          color="primary"
          variant="outlined"
          className={styles.checkButton}
          disabled={disabledButton || loading}
        >
          Check Serial Number
        </ButtonComponent>
      </form>
    </MainContainerComponent>
  );
}

export default CheckPage;
