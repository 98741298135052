import styles from "./HeadingComponent.module.css";
import { HeadingComponentProps } from "./types";

function HeadingComponent(props: HeadingComponentProps) {
  if (props.variant === "h1") {
    return (
      <h1 className={[styles.h1, props.className].join(" ")}>
        {props.children}
      </h1>
    );
  } else if (props.variant === "h2") {
    return (
      <h2 className={[styles.h2, props.className].join(" ")}>
        {props.children}
      </h2>
    );
  } else if (props.variant === "h3") {
    return (
      <h3 className={[styles.h3, props.className].join(" ")}>
        {props.children}
      </h3>
    );
  } else if (props.variant === "h4") {
    return (
      <h4 className={[styles.h4, props.className].join(" ")}>
        {props.children}
      </h4>
    );
  } else if (props.variant === "h5") {
    return (
      <h5 className={[styles.h5, props.className].join(" ")}>
        {props.children}
      </h5>
    );
  } else if (props.variant === "h6") {
    return (
      <h6 className={[styles.h6, props.className].join(" ")}>
        {props.children}
      </h6>
    );
  } else {
    return (
      <h1 className={[styles.h1, props.className].join(" ")}>
        {props.children}
      </h1>
    );
  }
}

export default HeadingComponent;
