import styles from "./FailedResultPage.module.css";
import { useNavigate } from "react-router-dom";

import MainContainerComponent from "../../components/MainContainerComponent";
import TitleComponent from "../../components/TitleComponent";
import ButtonComponent from "../../components/ButtonComponent";

function FailedResultPage() {
  const navigate = useNavigate();

  function _doCheckAnotherSerialNumber() {
    navigate(-1);
  }

  return (
    <MainContainerComponent>
      <div className={styles.titleContainer}>
        <TitleComponent title="Oops! Sorry" />
        <p>
          We couldn't find data for this serial number. Check your information
          and try again.
        </p>
      </div>
      <div className={styles.imageContainer}>
        <img
          src="https://res.cloudinary.com/monosolusi/image/upload/v1661225624/Mazuta/no-sn_e1skng.svg"
          alt="No Serial Number"
        />
      </div>
      <div className={styles.bottomButton}>
        <ButtonComponent
          variant="outlined"
          color="primary"
          onClick={_doCheckAnotherSerialNumber}
        >
          Check Another Serial Number
        </ButtonComponent>
      </div>
    </MainContainerComponent>
  );
}

export default FailedResultPage;
