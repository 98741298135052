import styles from "./TableHeaderComponent.module.css";
import { TableHeaderComponentProps } from "./types";

function TableHeaderComponent(props: TableHeaderComponentProps) {
  return (
    <thead className={styles.thead}>
      <tr>
        {props.items?.map((item, index) => (
          <td key={`header-${index}`}>{item}</td>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeaderComponent;
